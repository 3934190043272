let artModal = {

	openWithReloadData: function (mid, data) {
		let dl = I(mid);
		let url = dl.A('ref-src');
		if (Array.isArray(data)) {
			for (let i = data.length - 1; i >= 0; i--) {
				url = url.setURLParam(`args[${i}]`, data[i]);
			}
		} else {
			for (let key in data) {
				if (!data.hasOwnProperty(key)) continue;
				url = url.setURLParam(key, data[key]);
			}
		}
		// console.log('openWithReloadData', url);
		dl.setA('ref-src', url);

		return App.func.reloadEl(dl);
	},

};

window.artModal = artModal;